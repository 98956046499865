import { OxNFTContract, graphURL } from '../config';
import abi from '../config/abis/AeromatiX.json';
import axios from 'axios';
import { ethers } from 'ethers'

const API_KEY = 'P8QDBYHPIPBT6BRUZN6PXXKUQXC91WJFNH'; // Replace with your own Etherscan API key

function getDate(x) {
    const myDate = new Date(x * 1000);
    return myDate;
}


async function getLast10TransactionsAndEvents(library, userId, slot) {
    try {
        const provider = library

        console.debug(userId, slot)
      
        const options = (userId) ? {
            method: 'POST',
            url: graphURL,
            headers: {
                'content-type': 'application/json'
            },
            data: {
                query: `{
                    treePayoutEvents(first: 100, orderBy: blockTimestamp, orderDirection: desc, where: {to: ${userId}, level: ${slot}}) {
                        id
                        level
                        from
                        amount
                        blockNumber
                        blockTimestamp
                        transactionHash
                    }
    
                    directPaidEvents(first: 100, orderBy: blockTimestamp, orderDirection: desc, where: {to: ${userId}, level: ${slot}}) {
                        id
                        level
                        from
                        amount
                        blockNumber
                        blockTimestamp
                        transactionHash
                    }
                }`
            }
        } : {
            method: 'POST',
            url: graphURL,
            headers: {
                'content-type': 'application/json'
            },
            data: {
                query: `{
                    treePayoutEvents(first: 50, orderBy: blockTimestamp, orderDirection: desc) {
                        id
                        from
                        amount
                        blockNumber
                        blockTimestamp
                        transactionHash
                    }
    
                    directPaidEvents(first: 50, orderBy: blockTimestamp, orderDirection: desc) {
                        id
                        from
                        amount
                        blockNumber
                        blockTimestamp
                        transactionHash
                    }

                    aeroUsers(first: 50, orderBy: blockTimestamp, orderDirection: desc) {
                        userID
                        currentRefID
                        transactionHash
                        blockNumber
                        blockTimestamp
                    }
                }`
            }
        };
      
        const response = await axios.request(options);
        const res = response.data;
      
        const treePayoutEvents = res.data?.treePayoutEvents || [];
        const directPaidEvents = res.data?.directPaidEvents || [];
        // const upgradeEvents = res.data.upgradeEvents || [];
        const aeroUsers = res.data?.aeroUsers || [];

        let activities = [];
        let handleID = [];

        // Handle aeroUsers as Registration events
        aeroUsers.forEach(user => {
            const data = {
                txId: user.transactionHash,
                blockNumber: user.blockNumber,
                eventsName: 'Registration',
                userId: user.userID,
                referrerId: user.currentRefID,
                date: getDate(user.blockTimestamp).toDateString()
            };
            const key = `registration-${data.txId}-${parseInt(data.userId)}`;
            if(handleID.indexOf(key) === -1) {
                activities.push(data);
                handleID.push(key);
            }
        });

        // Handle TreePayout events
        treePayoutEvents.forEach(event => {
            const data = {
                txId: event.transactionHash,
                blockNumber: event.blockNumber,
                eventsName: 'TreePayout',
                userId: event.from,
                amount: event.amount,
                date: getDate(event.blockTimestamp).toDateString()
            };
            const key = `treePayout-${data.txId}-${parseInt(data.userId)}`;
            if(handleID.indexOf(key) === -1) {
                activities.push(data);
                handleID.push(key);
            }
        });

        // Handle DirectPaid events
        directPaidEvents.forEach(event => {
            const data = {
                txId: event.transactionHash,
                blockNumber: event.blockNumber,
                eventsName: 'DirectPaid',
                userId: event.from,
                amount: event.amount,
                date: getDate(event.blockTimestamp).toDateString()
            };
            const key = `directPaid-${data.txId}-${parseInt(data.userId)}`;
            if(handleID.indexOf(key) === -1) {
                activities.push(data);
                handleID.push(key);
            }
        });

        // Sort activities by block number in descending order
        activities.sort((a, b) => b.blockNumber - a.blockNumber);

        // Return first 10 or 20 activities based on userId
        return activities.slice(0, !userId ? 10 : 20);

    } catch(err) {
        console.debug("err", err);
        return [];
    }
}

export default getLast10TransactionsAndEvents;
