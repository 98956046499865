import React, { useCallback, useEffect, useState } from 'react'
import './LevelsDetails.css'
import CIcon from '@coreui/icons-react'
import { cilContact } from '@coreui/icons'
import { cilMeh } from '@coreui/icons'
import userIcon from '../../Assets/images/icons/user-icon.svg'
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import incomeIcon from '../../Assets/images/icons/income-icon.svg'
import useActiveWeb3React from '../../hooks/useWeb3'
import { useStakeContract, useUniLevelContract } from '../../hooks/useContract'
import { toast } from 'react-toastify'
import { ethers } from 'ethers'
import { getUserLevelTreeCountUni } from './fetchUserCount'
import { BASE_BSC_SCAN_URLS } from '../../config'
import iconsbg from '../../Assets/images/iconsbg.png'

function LevelsDetails() {
    const scrollToTop = () => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, 100);
    };

    const { library, chainId, account } = useActiveWeb3React();
    const [userId, setUserId] = useState(0);
    const [slot, setSlot] = useState(0);
    const [level, setLevel] = useState(0);
    const [totalUser, setTotalUser] = useState(0);
    const [totalEarning, setTotalEarning] = useState(0);
    const [userAddress, setUserAddress] = useState('');
    const [levelUsers, setLevelUsers] = useState([]);

    const location = useLocation();
    const navigate = useNavigate();
    const planC = useStakeContract();
    const planUni = useUniLevelContract();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const user_id = queryParams.get('user_id');
        const slot = queryParams.get('slot');
        const level = queryParams.get('level');
        if (queryParams.get('user_id')) {
            setUserId(user_id)
        }
        if (queryParams.get('slot')) {
            setSlot(slot)
        }
        if (queryParams.get('level')) {
            setLevel(level)
        }
    }, [setUserId, setSlot, setLevel, location])

    useEffect(() => {
        if (planC && userId !== 0) {
            fetSLOTLevels();
        }
    }, [userId, planC])

    const fetSLOTLevels = useCallback(async () => {
        let actDiv = document.getElementById('transactionUserLevels');
        actDiv.classList.add('loaderData');
        let userAddress;
        userAddress = await planC.getAddressById(userId);

        const userInfoResponse = await planC.userInfos(userAddress);

        if (userInfoResponse.joined == false) {
            toast.error("This user id does not exist");
            navigate("/");
        }

        let slotUsers = await planUni.userSlotCount(userAddress, slot);
        let slotEarning = await planUni.userSlotLevelEarnings(userAddress, slot, level - 1);

        setTotalEarning(ethers.utils.formatEther(slotEarning))
        setTotalUser(ethers.BigNumber.from(slotUsers).toNumber())

        let usersLevelTree = await getUserLevelTreeCountUni(userAddress, slot, level);
        const listItems = [];
        for (let i = 0; i < usersLevelTree.length; i++) {
            let dta = usersLevelTree[i]
            let data = {};

            let getUser = await planC.userInfos(dta.downUser);
            const getReferrer = await planC.userInfos(dta.referrer);

            data.amount = ethers.utils.formatEther(dta.amount);
            data.user = parseInt(getUser.id);
            data.referrer = parseInt(getReferrer.id);
            data.txn = dta.transactionHash;

            listItems.push(data)
        }
        setLevelUsers(listItems)
        actDiv.classList.remove('loaderData');
        // data = userLevelTree(userAddress,slot,level,i)
        // node user address - data.user
        // node user referrer - data.referrer
        // node user amount - data.amount

        // console.log("asd", usersLevelTree);
    });
    return (
        <>
            <section className="treeLevels dashboardMian">
                <div className='container'>
                    <div className='titleBox'>
                        <h2>
                            <NavLink className={"backIcon"} to={"/levels?user_id=" + userId} onClick={scrollToTop}>
                                <span>&#60;</span> back
                            </NavLink>
                        </h2>
                    </div>
                    <div className='levelDetailsTable'>
                        <section className='loginMian'>
                            <div className="container">
                                <div className="row cardbox">
                                    <div className='col-12 col-md-12 order-1 order-md-0 mb-40'>
                                        <div className='row'>
                                            <div className='col-6 col-sm-6 col-lg-3 mb-4'>
                                                <div className='inrBox position-relative'>
                                                    <div className='iconbgbox'>
                                                        <img src={incomeIcon} alt="partners icon" width="70" height="70" />
                                                    </div>
                                                    <div className='bottom'>
                                                        <h3>{slot}</h3>
                                                        <p>Slot</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-6 col-sm-6 col-lg-3 mb-4'>
                                                <div className='inrBox position-relative'>
                                                    <div className='iconbgbox'>
                                                        <img src={userIcon} alt="Unloack" width="70" height="70" />
                                                    </div>
                                                    <div className='bottom'>
                                                        <h3>{level}</h3>
                                                        <p>Level</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-6 col-sm-6 col-lg-3 mb-4'>
                                                <div className='inrBox position-relative'>
                                                    <div className='iconbgbox'>
                                                        <img src={incomeIcon} alt="Ratio icon" width="70" height="70" />
                                                    </div>
                                                    <div className='bottom'>
                                                        <h3>{Number(parseFloat(totalEarning ?? "0").toFixed(2))} OM</h3>
                                                        <p>Total Income</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-6 col-sm-6 col-lg-3 mb-4'>
                                                <div className='inrBox position-relative'>
                                                    <div className='iconbgbox'>
                                                        <img src={userIcon} alt="Profits" width="70" height="70" />
                                                    </div>
                                                    <div className='bottom'>
                                                        <h3>{totalUser}</h3>
                                                        <p>Total Users</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div class="titlebox mx-auto">
                            <h2>
                                <strong class="position-relative">Transaction</strong>
                                <span>
                                    History
                                    <img
                                        alt="Logo"
                                        loading="lazy"
                                        width="100"
                                        height="100"
                                        src={iconsbg} />
                                </span>
                            </h2>
                            <p class="text-justify text-md-center">
                                Earn rewards through a structured level-based system in O-Mantra. The more you engage and grow within the platform, the greater your income potential
                            </p>
                        </div>
                        <div className='tableInr' id='transactionUserLevels'>
                            <div className='table-responsive'>
                                <table className="table table-striped-columns">
                                    <thead>
                                        <tr className='headSec'>
                                            <th>User ID</th>
                                            <th>Sponsor</th>
                                            <th>Earning</th>
                                            <th>Tx Hash</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {levelUsers.map((a, index) => {
                                            return <tr key={index}>
                                                <td><a href="#!" className='idbox'>ID {a.user}</a></td>
                                                <td>{a.referrer}</td>
                                                <td>{Number(parseFloat(a.amount).toFixed(2))}</td>
                                                <td className='linkshr text-end'>
                                                    <span>View Txn</span>
                                                    <a href={`${BASE_BSC_SCAN_URLS}/tx/${a.txn}`} target="blank">
                                                        <svg className="w-6 h-6" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M4.232 7.232A2.5 2.5 0 0 1 6 6.5h5a.5.5 0 0 1 0 1H6A1.5 1.5 0 0 0 4.5 9v9A1.5 1.5 0 0 0 6 19.5h9a1.5 1.5 0 0 0 1.5-1.5v-5a.5.5 0 0 1 1 0v5a2.5 2.5 0 0 1-2.5 2.5H6A2.5 2.5 0 0 1 3.5 18V9a2.5 2.5 0 0 1 .732-1.768Z"></path><path fillRule="evenodd" clipRule="evenodd" d="M20.354 3.646a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.708-.708l10-10a.5.5 0 0 1 .708 0Z"></path><path fillRule="evenodd" clipRule="evenodd" d="M14.5 4a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 .5.5v5a.5.5 0 0 1-1 0V4.5H15a.5.5 0 0 1-.5-.5Z"></path></svg>
                                                    </a>
                                                </td>
                                            </tr>
                                        })}

                                        {levelUsers.length == 0 ? <tr><td colSpan={4}>No Record Found</td></tr> : ''}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default LevelsDetails