import React, { useCallback, useEffect, useState } from 'react';
import './Register.css'
import logofav from '../../Assets/images/logofav.webp'
import { Dropdown } from 'react-dropdown-now';
import useActiveWeb3React from '../../hooks/useWeb3';
import { useSelector } from 'react-redux';
import { useAccountInfo, useStake } from '../../state/account/hooks';
import { AGREEMENT, atxContract, BASE_URL, chainRPC, defaultNetwork, swapAddress, usdtContract, ValidatorAddress } from '../../config';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router';
import loaderGif from '../../Assets/images/loading.gif'
import Banner from '../../Components/Banner/Banner';
import { useContract, useStakeContract, useTokenContract } from '../../hooks/useContract';
import { ethers } from 'ethers';
import { Link } from 'react-router-dom';
import { registerToken } from '../../utils/setupNetwork'
import { useOcPrice } from '../../hooks/useOcPrice';
import ocicon from '../../Assets/images/tether.png'
import Web3 from 'web3';
import ValidatorABI from '../../config/abis/OxValidator.json'
import * as util from "ethereumjs-util";
import RegModal from '../../Components/RegModal/RegModal';
import FaqOuter from '../../Components/FaqOuter/FaqOuter';
import { toLocale } from '../../utils/pageHelpers';
import {
    CRow,
    CCol,
} from '@coreui/react'

const Loader = () => {
    return (
        <div className="loader"><img src={loaderGif} alt="loader GIF" width="70" height="70" /></div>
    )
}

const Register = (props) => {
    const [buttonPopup, setButtonPopup] = useState(false);
    const { chainId, account, library } = useActiveWeb3React()
    const { usdtBalance, isApproved, isRegistered } = useSelector((state) => state.account)
    const [upline, setUpline] = useState(1);
    const [eligible, setEligible] = useState(0);
    const [isInTrn, setTransaction] = useState(false);

    console.log(usdtBalance)

    const { approveUSDT, registerXgold, approveUSDT2 } = useStake()
    const [wicon, setwIcon] = useState('info');
    const [nicon, setnIcon] = useState('info');
    const [ricon, setrIcon] = useState('info');
    const [bicon, setbIcon] = useState('info');
    const [sicon, setsIcon] = useState('info');

    const [buttonText, setbuttonText] = useState('Register Now');
    const location = useLocation();

    const [signData, setSignData] = useState({});
    const validatorInstance = useContract(ValidatorAddress, ValidatorABI)
    const planC = useStakeContract();


    const validateRegisterProcess = useCallback(async () => {

        if (!isApproved) {
            document.getElementById("nextButton").disabled = false;
            setbuttonText("Approve OM");
        } else {
            if (!signData.v) {
                document.getElementById("nextButton").disabled = false;
                setbuttonText("Sign Agreement");
            } else {
                if (isRegistered) {
                    document.getElementById("nextButton").disabled = true;
                    toast.error("You are already registered please make login.");
                } else {
                    document.getElementById("nextButton").disabled = false;
                }
                setbuttonText("Register Now");
            }
        }

        const queryParams = new URLSearchParams(location.search);
        const referral = queryParams.get('referral');
        try {

            if (queryParams.get('referral')) {
                const qP = queryParams.get('referral')
                console.debug(qP, parseInt(qP))
                if (parseInt(qP) && parseInt(qP) !== NaN) {
                    setUpline(referral)
                }
            }
        } catch (error) {
            console.log(error)
            if (queryParams.get('referral')) {
                const qP = queryParams.get('referral')
                console.debug(qP, parseInt(qP))
                if (parseInt(qP) && parseInt(qP) !== NaN) {
                    setUpline(referral)
                }
            }
        }

        const walletConnectCheckDiv = document.getElementById('walletConnectCheck');
        const networkCheckDiv = document.getElementById('networkCheck');
        const registerCheckDiv = document.getElementById('registerCheck');
        const agreementCheckDiv = document.getElementById('agreementCheck');

        setEligible(0);
        console.log("validating register process", chainId, account)
        if (account) {
            walletConnectCheckDiv.classList.remove('error');
            walletConnectCheckDiv.classList.add('done');
            setwIcon('check_box');

            setEligible(eligible + 1);
        } else {
            walletConnectCheckDiv.classList.remove('done');
            walletConnectCheckDiv.classList.add('error');
            setwIcon('close');
        }

        if (chainId == defaultNetwork) {
            networkCheckDiv.classList.remove('error');
            networkCheckDiv.classList.add('done');
            setnIcon('check_box');
            setEligible(eligible + 1);
        } else {
            networkCheckDiv.classList.remove('done');
            networkCheckDiv.classList.add('error');
            setnIcon('close');
        }

        if (!isRegistered) {
            registerCheckDiv.classList.remove('error');
            registerCheckDiv.classList.add('done');
            setrIcon('check_box');
            setEligible(eligible + 1);
        } else {
            registerCheckDiv.classList.add('error');
            registerCheckDiv.classList.remove('done');
            setrIcon('close');
        }

        if (signData.v) {
            agreementCheckDiv.classList.remove('error');
            agreementCheckDiv.classList.add('done');
            setsIcon('check_box');
        } else {
            agreementCheckDiv.classList.remove('done');
            agreementCheckDiv.classList.add('error');
            setsIcon('close');
        }

    }, [location, setUpline, chainId, isApproved, usdtBalance, isRegistered, planC, account, signData])

    useEffect(() => {
        validateRegisterProcess();
    }, [account, usdtBalance, isApproved, isRegistered, isApproved, location, planC, signData, validateRegisterProcess])

    const navigate = useNavigate();

    useEffect(() => {
        console.log("checking balance", account)
        const timer = setInterval(() => {
            const balanceCheckDiv = document.getElementById('balanceCheck');
            if (parseFloat(usdtBalance) >= (2)) {
                balanceCheckDiv.classList.remove('error');
                balanceCheckDiv.classList.add('done');
                setbIcon('check_box');
                setEligible(eligible + 1);
            } else {
                balanceCheckDiv.classList.add('error');
                balanceCheckDiv.classList.remove('done');
                setbIcon('close');
            }
        }, 3500)
        return () => {
            console.log("clearing interval")
            clearInterval(timer)
        }
    }, [account, usdtBalance])

    const signMessage = useCallback(async () => {
        try {
            if (!account) {
                toast.error("Please connect your wallet first");
                return;
            }

            const signer = library.getSigner(account)
            const message = AGREEMENT

            const messageHash = ethers.utils.hashMessage(message);

            let signature = await signer.signMessage(message);
            let sig = ethers.utils.splitSignature(signature);
            // return;

            let r = sig.r;
            let s = sig.s;
            let v = sig.v;
            setSignData({ v, r, s, messageHash, signature });
        } catch (error) {
            console.error(error);
        }
    }, [account, library, validatorInstance])

    const validateUpline = useCallback((event) => {
        if (event.target.value) {
            setUpline(event.target.value)
        } else {
            setUpline('')
        }
    }, [setUpline])

    const approveBtn = useCallback(
        async () => {
            setTransaction(true)
            try {
                // console.log(account)
                await approveUSDT()
                // setTimeout( async ( ) => {
                await validateRegisterProcess();
                // }, 3000)
            } catch (err) {
                setTransaction(false)
            }
            setTransaction(false)
        },
        [approveUSDT, setTransaction, account],
    )

    const approve2Btn = useCallback(
        async () => {
            // setTransaction(true)
            try {
                // console.log(account)
                return await approveUSDT2()
                // setTimeout( async ( ) => {
                // await validateRegisterProcess();
                // }, 3000)
            } catch (err) {
                console.log(err)
                // setTransaction(false)
                return false;
            }
            // setTransaction(false)
        },
        [approveUSDT2, setTransaction, account],
    )

    const registerBtn = useCallback(
        async (upline) => {
            setTransaction(true)
            try {
                let resp = await registerXgold(upline, signData.v, signData.r, signData.s, signData.signature, signData.messageHash)
                if (resp) {
                    await validateRegisterProcess();
                    navigate("/")
                }
            } catch (err) {
                setTransaction(false)
            }
            setTransaction(false)
        },
        [registerXgold, setTransaction, signData],
    )

    const _doProcess = useCallback(async () => {
        if (!isApproved) {
            approveBtn();
        } else {
            if (!signData.v) {
                setTransaction(true)
                let resp = await approve2Btn();
                if (resp) {
                    signMessage()
                }
                setTransaction(false)
            } else {
                if (!upline || parseFloat(upline) == 0) {
                    toast.error("Invalid Upline ID")
                    return
                }
                registerBtn(upline);
            }
        }
    }, [upline, isApproved, account, approveBtn, registerBtn, signData, signMessage])

    const addBscTestnetToMetaMask = async () => {
        if (typeof window.ethereum === 'undefined') {
            toast.error('MetaMask is not installed. Please install MetaMask and try again.');
            return;
        }

        try {
            await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                    {
                        chainId: '0x89', // BSC Testnet chainId in hFexadecimal
                        chainName: 'Polygon Chain',
                        nativeCurrency: {
                            name: 'Pol',
                            symbol: 'b',
                            decimals: 18,
                        },
                        rpcUrls: [chainRPC],
                        blockExplorerUrls: ['https://polygonscan.com/'],
                    },
                ],
            });
        } catch (error) {
            console.error(error);
            toast.error('Error adding Polygon Chain to MetaMask: ' + error.message);
        }
    }

    return (
        <>
            <Banner />
            {/* <section className='loginRegisterBox'>
                <div className="container">
                    <div className="logRegInner">
                        <b className='buttonBox btn1 am_btn'><p id='walletConnectCheck'><i className="material-icons">{wicon}</i>Wallet Connected</p></b>
                        <b className='buttonBox btn2 am_btn'><p id='networkCheck'><i className="material-icons">{nicon}</i>Network</p></b>
                        <b className='buttonBox btn3 am_btn'><p id='registerCheck'><i className="material-icons">{ricon}</i>Registration</p></b>
                        <b className='buttonBox btn4 am_btn'><p id='balanceCheck'><i className="material-icons">{bicon}</i>Balance <b>(&gt;= {toLocale(usdtBalance)} OM)</b></p></b>
                        <b className='buttonBox btn5 am_btn'><p id='agreementCheck'><i className="material-icons">{sicon}</i>Agreement Signed</p></b>
                    </div>
                </div>
            </section> */}
            <section className='loginRegisterBox regpagebtngrp'>
                <div className="container">
                    <div className="logRegInner regpagebtngrp d-flex align-items-center justify-content-center flex-wrap">
                        <button className='buttonBox btn1 am_btn'><span id='walletConnectCheck' className='d-flex align-items-center justify-content-center gap-1'><i className="material-icons">{wicon}</i>Wallet Connected</span></button>
                        <button className='buttonBox btn1 am_btn'><span id='networkCheck' className='d-flex align-items-center justify-content-center gap-1'><i className="material-icons">{nicon}</i>Network</span></button>
                        <button className='buttonBox btn1 am_btn'><span id='registerCheck' className='d-flex align-items-center justify-content-center gap-1'><i className="material-icons">{ricon}</i>Registration</span></button>
                        <button className='buttonBox btn1 am_btn'><span id='balanceCheck' className='d-flex align-items-center justify-content-center gap-1'><i className="material-icons">{bicon}</i>Balance <b>(&gt;= 2 OM)</b></span></button>
                        <button className='buttonBox btn1 am_btn'><span id='agreementCheck' className='d-flex align-items-center justify-content-center gap-1'><i className="material-icons">{sicon}</i>Agreement Signed</span></button>
                    </div>
                </div>
            </section>
            <section className='contractsMain' style={{ paddingTop: '0px' }}>
                <div className='container'>
                    <div className="memberBox">
                        <h3>Mainnet & Token to Polygon Chain</h3>
                        <h5>How to Add Polygon Chain Mainnet</h5>
                        <p>Add Polygon Chain mainnet to metamask or other dapp browser to invest in O-Mantra NFT.</p>
                        <div className='autoLogin'>
                            <button onClick={addBscTestnetToMetaMask} className='am_btn mx-3 my-2'><span>Add Mainnet</span></button >
                            <button onClick={() => registerToken(usdtContract, "OM", 6, "https://polygonscan.com/token/images/mantradao_32.png?v=2")} className='am_btn my-2'><span>Add OM</span></button >
                        </div>
                    </div>
                    <CRow className='justify-content-center align-items-center mt-3 mb-5 dashmob'>
                        <CCol xs={12} md={12} lg={5} xl={5} className="accountPreviewBox regpagebox">
                            <div className='innerbox inerboxdash'>
                                <h2>Your upline</h2>
                                <p className='max-w[300px]'>Registration in O-Mantra NFT</p>

                                <div className='row inputBox'>
                                    <input type="text" value={upline} onChange={validateUpline} placeholder='Enter Your upline' />
                                    {
                                        buttonText === "Sign Agreement" &&
                                        <button className='am_btn apvbtn' data-coreui-toggle="modal" href="#regModalToggle">Register</button>
                                    }
                                    {buttonText !== "Sign Agreement" &&
                                        <button disabled={isInTrn} className='am_btn apvbtn' onClick={_doProcess} id='nextButton'> {isInTrn && <Loader />}
                                            {!isInTrn && buttonText}</button>
                                    }
                                </div>

                                <div className='brn-grp text-center mt-3'>
                                    <p className='mb-2'>Already have an account?</p>
                                    <Link to={"/"}>
                                        <button className='am_btn'><span>Login</span></button>
                                    </Link>
                                </div>

                            </div>
                        </CCol>
                    </CRow>
                </div>
            </section>
            <FaqOuter />
            <RegModal props={props} trigger={buttonPopup} isInTrn={isInTrn} buttonText={buttonText} onSign={_doProcess} setTrigger={setButtonPopup} />
        </>
    )
}

export default Register