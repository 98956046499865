import React, { useCallback, useState, useEffect } from 'react'
import logo from '../../Assets/images/logo.webp'
import textlogo from '../../Assets/images/textlogo.svg'
import oclogo from '../../Assets/images/logopng.png'
import './header.css'
import { Dropdown } from 'react-dropdown-now';
import 'react-dropdown-now/style.css';
import ConnectWallet from '../ConnectWallet/ConnectWallet';
import useAuth from '../../hooks/useAuth';
import { ConnectorNames } from '../../utils/web3React';
import useActiveWeb3React from '../../hooks/useWeb3';
import useDirectConnect from '../../hooks/useDirectConnect';
import { usePollBlockNumber } from '../../state/block/hooks';
import { useNavigate, useLocation } from 'react-router';

const Header = (props) => {
    const [buttonPopup, setButtonPopup] = useState(false);
    const { account } = useActiveWeb3React()
    const { login, logout } = useAuth()

    const connect = useCallback((connector) => {
        login(connector)
        setButtonPopup(false);
    })
    const location = useLocation();
    const navigate = useNavigate();
    const disconnectWallet = async () => {
        logout();
        navigate("/");
    }

    useDirectConnect()
    usePollBlockNumber()

    const options = [
        { label: 'Disconnect', value: 'logout' },
    ];

    const scrollToTop = () => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }, 100); // Scroll after 1 second delay
    };

    // Effect to trigger scrollToTop on location change
    useEffect(() => {
        scrollToTop();
    }, [location]);

    return (
        <>
            <header>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-6 col-md-6 text-start">
                            <div className="logo">
                                <a href="/"><img src={logo} className='logo' alt="logo" width="120" height="90" /><img src={textlogo} className='textlogo' alt="logo" width="120" height="90" /></a>
                            </div>
                        </div>
                        <div className="col-6 col-md-6 text-end">
                            <div className='rightboxmenu'>
                                {account ? <>
                                    <Dropdown placeholder={account ? (account.substr(0, 4) + '...' + account.substr(-4)) : "Connect Wallet"} options={options} onChange={disconnectWallet} />
                                    <img src={oclogo} className="profLogo" alt="oclogo" width="38" height="38" /></>
                                    : <ul className='list-unstyled m-0'>
                                        <li>
                                            <button onClick={() => account ? console.log('already connected') : setButtonPopup(true)} className='am_btn' data-coreui-toggle="modal" href="#connectWalletToggle"><span>Connect Wallet</span></button>
                                        </li>
                                    </ul>}
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <ConnectWallet props={props} onTrustWalletClick={() => connect(ConnectorNames.Injected)} onMetamaskClick={() => connect(ConnectorNames.Injected)} onWalletConnectClick={() => connect(ConnectorNames.WalletConnect)} trigger={buttonPopup} setTrigger={setButtonPopup} />
        </>
    )
}

export default Header