export const nftAddress = {
    1: "0x1E87507D5B38b6Cd55317fC1CE36e03dE44D4D41",
    2: "0xcb46F17eDDc8f05b914f22EbC3B0ACD89b84839a",
    3: "0xEb7286fFb972732B90179275878a14Db8Ae7Bf66",
    4: "0xABFBBE9F06aa0cBBe6eB38310A561193C213E6f1",
    5: "0x983eA115d135B7DafF79487E39C2Cc6d2c76f86D",
    6: "0xF5940c347e7228B27729bFA5Cf269190F6Ab3915",
    7: "0x8A27CeC2772E07BFa13FbB861808510321b40502",
    8: "0xCdD1c549480853fFd4ED2b128fA40c97E2de31e8",
    9: "0x10b60503f97265073EaDe5599f53aC962381D8C1",
    10: "0xeE342215498790Ce3f7BfAD8a533e63E42949b18",
    11: "0x975bAA236B323C76fa8aB8b00621952a59d502D2",
    12: "0xa2eB5818a81a669E8cDa62D0DDd53a3A565990F4",
    13: "0x7f223884aeE02fBB379a9ae4B32D49f6B53c1C73",
    14: "0xfCD6831544028e49fCC1a9C4a6d0fCB00F90eBBc",
    15: "0x21FBd6a8fbA16c3882c7f7E23d32F996EE3642ab"
}