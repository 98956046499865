import { OxNFTContract, usdtContract } from "../../config"
import { getContract } from "../../utils/getContract"
import tokenABI from '../../config/abis/tokenABI.json'
import AeromatiXABI from '../../config/abis/AeromatiX.json'
import { ethers } from "ethers"

export const fetchAccountUSDTBalance = async ({address}) => {
    const usdtInstance = getContract(usdtContract, tokenABI)
    const usdtBalance = await usdtInstance.balanceOf(address)
    console.log(usdtBalance, address)
    return ethers.utils.formatUnits(usdtBalance, 18)
}

// export const fetchAccountATXBalance = async ({address}) => {
//     const atxInstance = getContract(atxContract, tokenABI)
//     const atxBalance = await atxInstance.balanceOf(address)

//     console.log(atxBalance, address)
//     return ethers.utils.formatUnits(atxBalance, 18)
// }

export const fetchAccountApprove = async ({address}) => {
    const usdtInstance = getContract(usdtContract, tokenABI)
    const usdtApprove = await usdtInstance.allowance(address, OxNFTContract)
    return parseFloat(usdtApprove)
}

// export const fetchAtxAccountApprove = async ({address}) => {
//     const atxInstance = getContract(atxContract, tokenABI)
//     const atxApprove = await atxInstance.allowance(address, OxNFTContract)
//     return parseFloat(atxApprove)
// }

export const fetchAccountRegister = async ({address}) => {
    const usdtInstance = getContract(OxNFTContract, AeromatiXABI)
    const userInfo = await usdtInstance.userInfos(address)
    return userInfo.joined
}
