import CopyToClipboard from "react-copy-to-clipboard"
import { toast } from "react-hot-toast"

export const CopyButton = ({ text }) => {
    return <CopyToClipboard text={text} onCopy={() => toast.success("Copied Successfully!")}>

        <svg width="1792" height="1792" viewBox="0 0 1792 1792" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1696 384C1722.67 384 1745.33 393.333 1764 412C1782.67 430.667 1792 453.333 1792 480V1696C1792 1722.67 1782.67 1745.33 1764 1764C1745.33 1782.67 1722.67 1792 1696 1792H736C709.333 1792 686.667 1782.67 668 1764C649.333 1745.33 640 1722.67 640 1696V1408H96C69.3333 1408 46.6667 1398.67 28 1380C9.33333 1361.33 0 1338.67 0 1312V640C0 613.333 6.66667 584 20 552C33.3333 520 49.3333 494.667 68 476L476 68C494.667 49.3333 520 33.3333 552 20C584 6.66667 613.333 0 640 0H1056C1082.67 0 1105.33 9.33333 1124 28C1142.67 46.6667 1152 69.3333 1152 96V424C1197.33 397.333 1240 384 1280 384H1696ZM1152 597L853 896H1152V597ZM512 213L213 512H512V213ZM708 860L1024 544V128H640V544C640 570.667 630.667 593.333 612 612C593.333 630.667 570.667 640 544 640H128V1280H640V1024C640 997.333 646.667 968 660 936C673.333 904 689.333 878.667 708 860ZM1664 1664V512H1280V928C1280 954.667 1270.67 977.333 1252 996C1233.33 1014.67 1210.67 1024 1184 1024H768V1664H1664Z" fill="url(#paint0_linear_106_148)" />
            <defs>
                <linearGradient id="paint0_linear_106_148" x1="896" y1="0" x2="896" y2="1792" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFAEAE" />
                    <stop offset="0.45" stop-color="#FFAEAE" />
                    <stop offset="1" stop-color="#DABE35" />
                </linearGradient>
            </defs>
        </svg>

        {/* <svg fill="#FFFFFF" height="30" width="30" version="1.1" viewBox="0 0 330 330"><g><path d="M35,270h45v45c0,8.284,6.716,15,15,15h200c8.284,0,15-6.716,15-15V75c0-8.284-6.716-15-15-15h-45V15  c0-8.284-6.716-15-15-15H35c-8.284,0-15,6.716-15,15v240C20,263.284,26.716,270,35,270z M280,300H110V90h170V300z M50,30h170v30H95  c-8.284,0-15,6.716-15,15v165H50V30z"></path><path d="M155,120c-8.284,0-15,6.716-15,15s6.716,15,15,15h80c8.284,0,15-6.716,15-15s-6.716-15-15-15H155z"></path> <path d="M235,180h-80c-8.284,0-15,6.716-15,15s6.716,15,15,15h80c8.284,0,15-6.716,15-15S243.284,180,235,180z"></path><path d="M235,240h-80c-8.284,0-15,6.716-15,15c0,8.284,6.716,15,15,15h80c8.284,0,15-6.716,15-15C250,246.716,243.284,240,235,240z"></path></g></svg> */}
    </CopyToClipboard>
}