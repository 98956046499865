import React, { useCallback } from 'react'
import './Banner.css'
import { useNavigate } from 'react-router';
import useActiveWeb3React from '../../hooks/useWeb3';
import toast, { Toaster } from 'react-hot-toast';
import { useStakeContract } from '../../hooks/useContract';
import bannerimg from '../../Assets/images/banner1.png';
import chakrabannerimg from '../../Assets/images/chakrabannerimg.webp';
import { OxNFTContract, atxContract, BASE_URL, defaultNetwork, usdtContract, chainRPC } from '../../config';
import { registerToken } from '../../utils/setupNetwork';
import { Link } from 'react-router-dom';

const Banner = () => {

    const navigate = useNavigate();
    const { library, chainId, account } = useActiveWeb3React()
    const planC = useStakeContract();
   
    const addPolygonToMetaMask = async () => {
        if (typeof window.ethereum === 'undefined') {
            toast.error('MetaMask is not installed. Please install MetaMask and try again.');
            return;
        }

        try {
            await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                    {
                        chainId: '0x89', // BSC Testnet chainId in hexadecimal
                        chainName: 'Polygon Chain',
                        nativeCurrency: {
                            name: 'Pol',
                            symbol: 'Pol',
                            decimals: 18,
                        },
                        rpcUrls: [chainRPC],
                        blockExplorerUrls: ['https://polygonscan.com/'],
                    },
                ],
            });
        } catch (error) {
            console.error(error);
            toast.error('Error adding Polygon Chain to MetaMask: ' + error.message);
        }
    }
    const makeAutoLogin = useCallback(async () => {
        if (!account) {
            toast.error("Connect wallet!")
            return
        }
        try {
            const userInfo = await planC.userInfos(account)
            // console.log(userInfo)
            if (userInfo.joined) {
                navigate("/dashboard?user_id=" + parseInt(userInfo.id))
            } else {
                navigate("/register")
            }
        } catch (Err) {
            console.log(Err)
        }
    }, [account, planC])

    return (
        <>
            <section className="sf_banner position-relative">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-12 text-center">
                            <div className='contentinfolt'>
                                <h1>Unlock the New Era of Digital Possessions.</h1>
                                {/* <p>Step into the future of ownership with our revolutionary NFT platform, where digital assets meet limitless possibilities.</p> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container my-4">
                    <div className="row align-items-center mx-auto  text-center">
                        <div class="col-12 col-md-6 mx-auto">
                            <div className='circlebox position-relative'>
                                <img className="circleimg" src={bannerimg} alt="circle" width="600" height="600" />
                                <img className="logopng" src={chakrabannerimg} alt="logopng" width="60" height="60" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Banner