import React, { useEffect, useState } from 'react';
import { Link, useLocation, NavLink } from 'react-router-dom';
import { cilHome, cilWallet, cilAccountLogout, cilUserFollow, cilSoccer, cilGrain, cilPool, cilInfo, cilList } from '@coreui/icons'
import CIcon from '@coreui/icons-react'

import './Sidebar.css'
const Sidebar = () => {
    const [userId, setUserId] = useState(0);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    useEffect(() => {
        const user_id = queryParams.get('user_id');
        if (queryParams.get('user_id')) {
            setUserId(user_id)
        }
        function addClass(elem) {
            for (let i = 0; i < elem.length; i++) {
                elem[i].addEventListener("click", function (e) {
                    const current = this;
                    for (let i = 0; i < elem.length; i++) {
                        if (current !== elem[i]) {
                            elem[i].classList.remove("isActive");
                            elem[i].classList.add("notActive");
                        } else {
                            current.classList.add("isActive");
                            current.classList.remove("notActive");
                        }
                    }
                    // e.preventDefault();
                });
            }
        }
        addClass(document.querySelectorAll(".link"));
    }, []);

    const scrollToTop = () => {
        setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, 100);
    };

    return (
        <section className="sidebarMenu">
            <div className="container">
                <nav>
                    <ul>
                        <li>
                            <NavLink to={"/dashboard?user_id=" + userId} activeClassName="active" onClick={scrollToTop}>
                                <CIcon icon={cilHome} size="xl" />
                                <span>Dashboard</span>
                            </NavLink >
                        </li>
                        <li>
                            <NavLink to={"/mynfts?user_id=" + userId} activeClassName="active" onClick={scrollToTop}>
                                <CIcon icon={cilGrain} size="xl" />
                                <span>My NFT</span>
                            </NavLink >
                        </li>
                        <li>
                            <NavLink to={"/partners?user_id=" + userId} activeClassName="active" onClick={scrollToTop}>
                                <CIcon icon={cilUserFollow} size="xl" />
                                <span>Partners</span>
                            </NavLink >
                        </li>
                        <li>
                            <NavLink to={"/stats?user_id=" + userId} activeClassName="active" onClick={scrollToTop}>
                                <CIcon icon={cilSoccer} size="xl" />
                                <span>Stats</span>
                            </NavLink>
                        </li>
                        {/* <li>
                        <NavLink to={"/levelsdetails?user_id=" + userId} activeClassName="active" onClick={scrollToTop} className="poolOverviewPg" style={{ display: "none" }}></NavLink>
                        <NavLink to={"levels?user_id=" + userId} activeClassName="active" onClick={scrollToTop}>
                            <CIcon icon={cilList} size="xl" />
                            <span>Unilevel Income</span>
                        </NavLink>
                    </li> */}
                        <li>
                            <NavLink to={"/pools?user_id=" + userId} activeClassName="active" onClick={scrollToTop} className="poolOverviewPg" style={{ display: "none" }}></NavLink>
                            <NavLink to={"pools?user_id=" + userId} activeClassName="active" onClick={scrollToTop}>
                                <CIcon icon={cilList} size="xl" />
                                <span>Pools</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to={"/faq?user_id=" + userId} activeClassName="active" onClick={scrollToTop}>
                                <CIcon icon={cilInfo} size="xl" />
                                <span>FAQs</span>
                            </NavLink>
                        </li>
                    </ul>
                </nav>
            </div>
        </section>
    )
}

export default Sidebar