import React from 'react'
import Footer from '../../Components/Footer/Footer'
import './Faq.css'
import { CRow, CCol, CAccordion, CAccordionBody, CAccordionHeader, CAccordionItem } from '@coreui/react';
import iconsbg from '../../Assets/images/iconsbg.png'

const Faq = () => {
    return (
        <>
            <>
                <section className="faqOuterSection dashboardMian">
                    <div className='container'>
                        <div class="titlebox mb-3 mb-md-5 mx-auto">
                            <h2>
                                <strong class="position-relative">Our FAQ's</strong>
                                <span>
                                    O-Mantra
                                    <img
                                        alt="Logo"
                                        loading="lazy"
                                        width="100"
                                        height="100"
                                        src={iconsbg} />
                                </span>
                            </h2>
                            <p class="text-justify text-md-center">
                                Find answers to common questions about O-Mantra, its features, and how to get started with the platform. Get the support you need to navigate the ecosystem.
                            </p>
                        </div>
                        <CRow className='faqrow'>
                            <CCol xs={12}>
                                <div className="rowtreebox" id='SlotLevelDetails'>
                                    <CAccordion activeItemKey={0}>
                                        <CAccordionItem>
                                            <CAccordionHeader className='treebox'>
                                                <span>#1</span><b>What is Decentralization?</b>
                                            </CAccordionHeader>
                                            <CAccordionBody>
                                                <p>It is a type of server architecture where the entire code is deployed in a manner that is never editable once deployed. It uses Blockchain technology & it is highly trustable & stable.</p>
                                            </CAccordionBody>
                                        </CAccordionItem>
                                        <CAccordionItem>
                                            <CAccordionHeader className='treebox'>
                                                <span>#2</span><b>What are Smart Contracts?</b>
                                            </CAccordionHeader>
                                            <CAccordionBody>
                                                <p>Smart contracts are simply programs stored on a blockchain that run when predetermined conditions are met. They are typically used to automate the execution of an agreement so that all participants can be immediately sure of the outcome without any intermediary's involvement or time loss. They can also automate a workflow, triggering the subsequent action when conditions are met.</p>
                                            </CAccordionBody>
                                        </CAccordionItem>
                                        <CAccordionItem>
                                            <CAccordionHeader className='treebox'>
                                                <span>#3</span><b>O-Mantra NFT Smart Contract</b>
                                            </CAccordionHeader>
                                            <CAccordionBody>
                                                <p>O-Mantra NFT smart contract never stores participants' funds, which means their balance is always zero. Here, the purpose of a smart contract is to trigger events that involve redirecting funds from incoming transactions to the participants' wallets.</p>
                                            </CAccordionBody>
                                        </CAccordionItem>
                                        <CAccordionItem>
                                            <CAccordionHeader className='treebox'>
                                                <span>#4</span><b>Referral linking</b>
                                            </CAccordionHeader>
                                            <CAccordionBody>
                                                <p>Once a referral link is created, it can't be changed manually or through smart contracts as well. Upline & Downline are assigned to you in your structure forever.</p>
                                            </CAccordionBody>
                                        </CAccordionItem>
                                        <CAccordionItem>
                                            <CAccordionHeader className='treebox'>
                                                <span>#5</span><b>Personal Wallet</b>
                                            </CAccordionHeader>
                                            <CAccordionBody>
                                                <p>To become a member, you need to create your wallet in Metamask or Trust Wallet app. Only you have access to the funds. All rewards as per the plan are instantly credited to your wallet. All transactions are stored in the public domain in a blockchain. You can quickly check each transaction and see where the funds have been transferred.</p>
                                            </CAccordionBody>
                                        </CAccordionItem>
                                        <CAccordionItem>
                                            <CAccordionHeader className='treebox'>
                                                <span>#6</span><b>Registration in O-Mantra NFT Plan</b>
                                            </CAccordionHeader>
                                            <CAccordionBody>
                                                <p>Your registration is free in O-MantraNFT, as when you buy the NFT in every slot, you will get a free user ID registered, and you can earn referral earnings.</p>
                                            </CAccordionBody>
                                        </CAccordionItem>
                                        <CAccordionItem>
                                            <CAccordionHeader className='treebox'>
                                                <span>#7</span><b>O-Mantra NFT Plan Explained</b>
                                            </CAccordionHeader>
                                            <CAccordionBody>
                                                <p>- You get a 30% payment for direct referrals (Unlimited referrals).</p>
                                                <p>- 15% Distributed in Royalty Pool, 5% - Cosmic Harmony, 5% - Celestial Energy, and 5% - Eternal Zenith.</p>
                                                <p>- From the 1st line, you get 5%. From each of the 2 places.</p>
                                                <p>- From the 2nd line, you get 10%. From each of the 4 places.</p>
                                                <p>- From the 3rd line, you get 15%. From each of 8 places.</p>
                                                <p>- And from the 4th line, you get 25% from 16 places.</p>
                                            </CAccordionBody>
                                        </CAccordionItem>
                                        <CAccordionItem>
                                            <CAccordionHeader className='treebox'>
                                                <span>#8</span><b>O-Mantra NFT - Rebooking</b>
                                            </CAccordionHeader>
                                            <CAccordionBody>
                                                <p>The last 4 payments from the 4th line, 25% + 25% + 25% +25 %, are used for repeat 100%, which is distributed to upline partners, and a new cycle of the level begins automatically.</p>
                                            </CAccordionBody>
                                        </CAccordionItem>
                                        <CAccordionItem>
                                            <CAccordionHeader className='treebox'>
                                                <span>#9</span><b>Who can upgrade user's slots?</b>
                                            </CAccordionHeader>
                                            <CAccordionBody>
                                                <p>Only the user can upgrade the slots.</p>
                                            </CAccordionBody>
                                        </CAccordionItem>
                                        {/* <CAccordionItem>
                                            <CAccordionHeader className='treebox'>
                                                <span>#10</span><b>What happens if the stored fund exceeds the price of the next slot?</b>
                                            </CAccordionHeader>
                                            <CAccordionBody>
                                                <p>The excess amount will be transferred to the self-user wallet if the locked fund exceeds the amount after the slot purchase.</p>
                                            </CAccordionBody>
                                        </CAccordionItem> */}
                                        {/* <CAccordionItem>
                                            <CAccordionHeader className='treebox'>
                                                <span>#11</span><b>What will the user do with the second-cycle funds?</b>
                                            </CAccordionHeader>
                                            <CAccordionBody>
                                                <p>Funds from the second cycle of every slot will be locked, and users can only use them to buy the next slot first. If the locked amount exceeds the slot amount, the remaining amount will be sent to the user's wallet once the slot purchase is confirmed.</p>
                                            </CAccordionBody>
                                        </CAccordionItem> */}
                                        {/* <CAccordionItem>
                                            <CAccordionHeader className='treebox'>
                                                <span>#12</span><b>Can the user buy second slots using the stored amount of the previous slot?</b>
                                            </CAccordionHeader>
                                            <CAccordionBody>
                                                <p>Suppose the locked funds exceed the slot purchase price. The surplus amount will be transferred to the user's wallet. On the other hand, if the slot's stored amount is less than the slot price, you can add on the extra amount or wait until the locked amount reaches the slot price.</p>
                                            </CAccordionBody>
                                        </CAccordionItem> */}
                                        <CAccordionItem>
                                            <CAccordionHeader className='treebox'>
                                                <span>#10</span><b>How does Rebooking work Automatically?</b>
                                            </CAccordionHeader>
                                            <CAccordionBody>
                                                <p>Automatic cycles open at the same level for you, and you continue to receive income from them. This happens automatically as soon as the last free spot under you is taken by a partner. The current level completes its cycle, and the next cycle begins. When a cycle is completed, you occupy a free spot in the level of your upline partner. And you have your level reopened with free spots for new partners. For each cycle, a reward of 100% goes to your upline partner's wallet. Cycles work in the same way for your invited partners, and each time their level completes a cycle, you receive a reward for it. Activating several levels and asking partners to those levels at once speeds up your profit many times. The levels do not have a validity period, so you should not be afraid that they will expire. Each active level constantly brings you income. How many levels can be activated at once? As many as you want, even all at once.</p>
                                            </CAccordionBody>
                                        </CAccordionItem>
                                    </CAccordion>
                                </div>
                            </CCol>
                        </CRow>

                    </div>
                </section>
            </>
        </>
    )
}

export default Faq