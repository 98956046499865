import React from 'react';
import { CRow, CCol, } from '@coreui/react'
import { Home, Info, EvStationSharp, PoolTwoTone, People, QueryStatsIcon } from '@material-ui/icons';
import { FaTelegramPlane, FaRegEnvelope, FaTwitter, FaMediumM, FaInstagram, FaRedditAlien, FaFacebookF, FaYoutube, FaLinkedinIn, FaDiscord } from "react-icons/fa";
import './Footer.css'
const Footer = () => {

    return (
        <footer>
            <div className='container'>
                <CRow className='align-items-center justify-content-center'>
                    <CCol xs={12} className="text-right">
                        <div className="socialIcons">
                            <ul>
                                <li>
                                    <a href="#" rel="noreferrer" title="Telegram" target="_blank">
                                        <FaFacebookF />
                                    </a>
                                </li>
                                <li>
                                    <a href="#" rel="noreferrer" title="Telegram" target="_blank">
                                        <FaInstagram />
                                    </a>
                                </li>
                                <li>
                                    <a href="#" rel="noreferrer" title="Telegram" target="_blank">
                                        <FaTelegramPlane />
                                    </a>
                                </li>
                                <li>
                                    <a href="#" rel="noreferrer" title="Telegram" target="_blank">
                                        <FaYoutube />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </CCol>
                    <CCol xs={12}><p className='text-center'>© 2024 All Rights Reserved</p></CCol>
                </CRow>
            </div>
        </footer>
    )
}

export default Footer