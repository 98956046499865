import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAccountApprove, fetchAccountATXBalance, fetchAccountRegister, fetchAccountUSDTBalance, fetchAtxAccountApprove } from "./fetchAccountInfo";

export const fetchAccountInfo = createAsyncThunk("account/fetchAccountIn", async ( address ) => {
    try {
        const usdtBalance = await fetchAccountUSDTBalance({address})
        console.log(address)

        let isApproved = await fetchAccountApprove({address})
        let isRegistered = false
        try {
            isRegistered = await fetchAccountRegister({address})
        } catch(err) {
            console.log("Error fetching account info", err)
        }

        return {
            usdtBalance: usdtBalance,
            isApproved,
            isRegistered
        }
    } catch(err){
        console.log("Error fetching account info", err)
        return {
            usdtBalance: 0,
            isApproved: false,
            isRegistered: false
        }
    }
})